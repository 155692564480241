export const NetworkContextName = "Ethereum Mainnet";
export const ACTIVE_NETWORK = 1;
export const GalacticEagleContract =
  "0xe383598fb6628687e39697c646e62672637dce31";
export const baseURI =
  "https://ipfs.io/ipfs/QmdP6VwefyuVTz3k7n2AcPv16HxJ3fz3zN23vAkJY9S6ST";

export const RPC_URL =
  "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";

export const NetworkDetails = [
  {
    chainId: "0x1",
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ethereum Mainnet",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
];
