import React from "react";
import {
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    backgroundColor: "#000",
    borderTop: "1px solid #525252",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    "& p": {
      fontSize:"18px",
      margin:"0",
      padding:"10px 0"
    },
    "& a": {
      fontSize:"18px",
      margin:"0",
      cursor:"pointer",
      padding:"10px 0",
      textDecoration:"none",
      color:"#989797",
      "&:hover":{
        textDecoration:"none",
      },
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    justifyContent: "center",
    "& a": {
      width: "30px",
      height: "30px",
      borderRadius: " 50%",
      backgroundColor: " #fff",
      color: "#000",
      margin: "0 10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      padding:"0",
      "&:hover": {
        backgroundColor: "#08d57f",
        color: "#fff",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (

    <Box mt={5} className={classes.footerSection} >
      <Container maxWidth="lg" align="center">
        <img src="images/logo.png" style={{ width: "80px" }} alt="Logo" />
        <ul className={classes.menuul}>
        <li> <div><a href="#" target="_blank"><AiOutlineTwitter /></a></div></li>
        <li><div><a href="#" target="_blank"><AiOutlineInstagram /></a></div></li>
        </ul>
        <Link to="/terms">Terms of Service</Link>
        <p>© 2021 Galactic Eagle. All rights reserved</p>
      </Container>
    </Box>

  );
}